import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Amplify from 'aws-amplify';
import config from './aws-exports';
Amplify.configure(config);
Amplify.configure({
  API: {
    "aws_cloud_logic_custom": [
      {
          "name": "imageapi",
          "endpoint": "https://d7oqdnlrrf.execute-api.us-east-1.amazonaws.com/staging",
          "region": "us-east-1"
      },
      {
          "name": "imageclass",
          "endpoint": "https://zr84qort75.execute-api.us-east-1.amazonaws.com/Stage",
          "region": "us-east-1"
      }          
    ],
  }
});


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
