/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:35e724cd-2dc8-498e-9c71-c57be4ae5460",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_VL8bDYClM",
    "aws_user_pools_web_client_id": "4n3c0m87od4glqbf5grlodp3lp",
    "oauth": {},
    "aws_user_files_s3_bucket": "imgproc-data212120-staging",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "imageapi",
            "endpoint": "https://d7oqdnlrrf.execute-api.us-east-1.amazonaws.com/staging",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
